import React, { useState } from 'react'
import SEO from '../../components/seo'
import SubPageLayoutNoHero from '../../layouts/subPageLayoutNoHero'
import styled from 'styled-components'
import { Section } from '../../components/styled/section'
import { Layout } from '../../components/styled/layout'
import SelectWorkshopModal from '../../components/selectWorkshopModal'
import { generateWorkshopPagePrefix } from '../../url-helpers'
import { navigate } from 'gatsby'
import SubPageImgHero from '../../components/subPageImgHero'
import ChooseRegion from '../../components/small_web_comp/ChooseRegion'
const PageSection = styled(Section)`
  display: flex;
  align-items: center;
  margin: 15px auto;
`
const Text = styled.div`
  flex: 1;
  margin-top: -3rem;
  h4 {
    border-bottom: 2px solid #dddddd;
  }
`

/*
const Style = styled.div`
  #button {
    display: hidden;
  }
`*/

const ACservic = () => {
  const [showWorkshopSelector, setShowWorkshopSelector] = useState(false)
  const title = 'A/C Service'
  const description1 =
    'Har du dårlig luftsirkulasjon i bilen? For å sikre god luftfuktighet og -sirkulasjon er det viktig med jevnlig vedlikehold av AC-anlegget. Våre mekanikere har solid erfaring med AC- og klimaservice, og hjelper deg gjerne!'
  const Img = require('../../images/Bilde_AC.png')
  return (
    <Layout>
      {showWorkshopSelector && (
        <SelectWorkshopModal
          onClose={() => setShowWorkshopSelector(false)}
          onSelected={async (workshop) => {
            const prefix = generateWorkshopPagePrefix(workshop)
            await navigate(`/${prefix}/${workshop.slug}#Bestilltime`)
          }}
        />
      )}
      <SubPageLayoutNoHero title={title} description1={description1}>
        <SEO title={title} />
        <SubPageImgHero title={title} description1={description1} Img={Img}></SubPageImgHero>
        <PageSection>
          <Text>
            <h4>Klimaservice</h4>
            <p>
              Få ting er verre enn et ikke-fungerende klimaanlegg i 30 varmegrader. I tillegg til å
              kunne kjøle seg ned på varme dager, er god luftsirkulasjon viktig for å forhindre dugg
              på ruten og å holde konsentrasjonen når du kjører bil.
              <p>
                En AC-service gjør at vi finner ut av årsaken og kan fikse klimaanlegget, slik at du
                igjen kan få frisk luft inn i bilen.
              </p>
              <p>
                Hvis effekten av ditt klimaanlegg blir dårligere og dårligere kan dette skyldes for
                lite gass. Hvorfor blir det for lite gass? En årsak kan være en liten lekkasje på
                anlegget, eller at det er lenge siden - kanskje aldri - blitt etterfylt gass. Ved
                lekkasje undersøker vi hvor lekkasjen kommer fra, og bytter delen som slipper ut
                gassen.
              </p>
              <p>
                Viktig å ta en AC-service/klimaanlegg service, når det blir fylt på gass vil det
                også fylles olje i systemet slik at man unngår havari på kompressoren. Vi utfører
                også klimarens med allergivennlige rensemidler.
              </p>
            </p>
            <h4>Klimarens</h4>
            <p>
              Lukter det kjeller når du setter på AC-anlegget? Da er det på tide å ta en klimarens.
              Dette gjøres ved at man spyler fordamperen (registeret) som sender luft inn i kupeen.
            </p>
            <p>
              Ved spyling av fordamperen med allergifri kjemi fjerner vi døde innsekter, blader fra
              trærne, og annet smuss som samler seg og danner bakterier i lufta som skal slippes inn
              i bilen.
            </p>
            <p>
              Har du behov for Klimaservice eller klimarens?
              <ChooseRegion />
            </p>
          </Text>
        </PageSection>
      </SubPageLayoutNoHero>
    </Layout>
  )
}

export default ACservic
