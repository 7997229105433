import styled from 'styled-components'
import { device } from '../device'
import React from 'react'

export const WorkshopList = styled.ul`
  flex: 1;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-right: 3rem;
  width: 100%;

  border: 1px solid #dddddd;
  overflow-x: hidden;

  @media only screen and ${device.laptop} {
    height: 455px;
  }
`

const WorkshopListItemStyled = styled.li`
  margin: 0;
  border-bottom: 1px solid #dddddd;
  :last-of-type {
    border-bottom: none;
  }
  a {
    display: block;
    padding: 1rem;
    color: inherit;
    text-decoration: none;
    transition: background 0.25s ease-out;
  }
  a:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
  }
`

export const WorkshopListItem = ({ workshop, onClick }) => {
  return (
    <WorkshopListItemStyled>
      <WorkshopButton type="button" onClick={() => onClick(workshop)}>
        <strong>{workshop.name}</strong>
        <br />
        <small>
          {workshop.street} {workshop.zip} {workshop.city}
          {workshop.phone && (
            <>
              <br />
              <span>{workshop.phone}</span>
            </>
          )}
        </small>
      </WorkshopButton>
    </WorkshopListItemStyled>
  )
}

export const WorkshopButton = styled.button`
  width: 100%;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background: inherit;
  text-align: left;
  border: none;
  padding: 1rem;
  transition: background 0.25s ease-out;
  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
  }
`
