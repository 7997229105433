import React from 'react'
import FilterableWorkshopList from './filterableWorkshopList'
import styled from 'styled-components'
import { useWorkshops } from '../hooks/use-workshops'
import { device } from './device'

const ModalOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
`

const ModalBody = styled.div`
  position: relative;
  padding: 2rem;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);

  max-width: 1000px;
  height: 70%;
  border-radius: 2px;
  background: white;
  overflow-x: hidden;

  @media ${device.tablet} {
    //max-width: 100%;
    overflow-x: hidden; //horizontal
    overflow-y: scroll; //vertical
  }
  @media ${device.tabletMaxL} {
    width: 100%;
    overflow-x: hidden; //horizontal
    overflow-y: scroll; //vertical
  }
`

const ModalHeader = styled.div`
  position: relative;
  margin-bottom: 2rem;
`

const ModalCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  font-size: inherit;
  padding: 0.5rem 1rem;
  background: transparent;
  border: 1px solid #dddddd;
`

const ModalTitle = styled.h2`
  text-align: center;
  margin-top: 0;
  font-weight: 700;
  @media ${device.tabletMaxL} {
    font-size: 1.5rem;
    text-align: left;
  }
`

const ModalContent = styled.div`
  height: calc(100% - 3rem);
  overflow-x: scroll;
`

const SelectWorkshopModal = ({ onClose, onSelected }) => {
  const workshops = useWorkshops()

  return (
    <ModalOverlay>
      <ModalBody>
        <ModalHeader>
          <ModalTitle>Velg verksted</ModalTitle>
          <ModalCloseButton type="button" onClick={onClose}>
            Lukk
          </ModalCloseButton>
        </ModalHeader>
        <ModalContent>
          <FilterableWorkshopList workshops={workshops} onClick={onSelected} />
        </ModalContent>
      </ModalBody>
    </ModalOverlay>
  )
}

export default SelectWorkshopModal
