import React from 'react'
import styled from 'styled-components'

const StyledTextField = styled.input`
  width: 100%;
  margin: 5px;
  padding: 1rem;
  font-family: inherit;
  font-size: 1rem;
`

const TextField = ({ id, name, value, required, placeholder, onChange }) => {
  return (
    <StyledTextField
      id={id}
      type="text"
      name={name}
      required={required}
      value={value}
      placeholder={placeholder}
      onChange={({ target: { value } }) => onChange(value)}
    />
  )
}

export default TextField
