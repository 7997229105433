import styled from 'styled-components'
import { device } from '../device'

export const FormGroup = styled.div`
  margin-bottom: 2rem;
`

export const InlineFormGroup = styled.div`
  @media ${device.laptopL} {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;

    h5 {
      flex: 1;
      margin: 0;
    }

    input {
      flex: 4;
    }

    button {
      flex: 2;
    }
  }
`

export const HelpText = styled.small`
  margin: 0;
  display: block;
  text-align: right;
`

export const Label = styled.label`
  display: inline-block;
  margin-right: 1rem;
  font-size: 1.2rem;
`

export const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  font-family: inherit;
  font-size: 1rem;
  resize: none;
  min-height: 8rem;
`
