import React, { useEffect, useState } from 'react'
import { FormGroup } from './styled/forms'
import { WorkshopList, WorkshopListItem } from './styled/workshopList'
import TextField from './web-order-v3/Field/textfield'
import styled from 'styled-components'
import { useWorkshops } from '../hooks/use-workshops'
const SelectedPageText = styled.small`
  font-weight: bold;
`

const findWorkshopsConnectedToPages = (matchingPages, workshops) => {
  const pageIds = matchingPages.map((p) => p.page_id)

  return Array.from(
    new Set(
      pageIds
        .map((pageId) => workshops.filter((w) => w.collection_pages.indexOf(pageId) !== -1))
        .flatMap((w) => w)
    )
  )
}

const FilterableWorkshopList = ({ selectedPage, collectionPages, onClick }) => {
  const [filter, setFilter] = useState('')
  const [matchingPages, setMatchingPages] = useState([])
  //const [isSearching, setIsSearching] = useState(false)
  const workshops = useWorkshops()
  // Match name of collection page with filter
  useEffect(() => {
    if (!filter) {
      setMatchingPages([])
      return
    }
  }, [filter, setMatchingPages, collectionPages])

  const includesFilterInName = (workshop) =>
    workshop.name.toLowerCase().includes(filter.toLowerCase())
  const matchingWorkshops = findWorkshopsConnectedToPages(matchingPages, workshops)

  const allWorkshops = Array.from(
    new Set([...workshops.filter(includesFilterInName), ...matchingWorkshops])
  )

  return (
    <React.Fragment>
      <FormGroup>
        <TextField name="search" placeholder="Søk etter verksted" onChange={setFilter} />

        {selectedPage && (
          <SelectedPageText>Viser verksted tilknyttet {selectedPage.name}</SelectedPageText>
        )}

        {matchingPages?.length > 0 && (
          <SelectedPageText>
            Viser verksted tilknyttet {matchingPages.map((p) => p.name).join(', ')}
          </SelectedPageText>
        )}
      </FormGroup>

      {allWorkshops?.length > 0 ? (
        <WorkshopList>
          {allWorkshops.map((workshop, index) => (
            <WorkshopListItem key={index} workshop={workshop} onClick={onClick} />
          ))}
        </WorkshopList>
      ) : (
        <div>Ingen verksted funnet</div>
      )}
    </React.Fragment>
  )
}

export default FilterableWorkshopList
